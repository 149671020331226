<template>
  <div>
    <div class="fd-img-wrap">
      <img style="width:100%" src="../assets/images/tj-banner.png" alt="">
    </div>
    <div class="fd-tj-content-wrap">
      <div class="fd-tj-left-wrap">
        <div class="fd-tj-left-item" :class="{'fd-checked': keyIndex === 0}" @click="adcpClick(0)">实体基地</div>
        <div class="fd-tj-left-item" :class="{'fd-checked': keyIndex === 1}" @click="adcpClick(1)">互联网投教</div>
        <div class="fd-tj-left-item" :class="{'fd-checked': keyIndex === 2}" @click="adcpClick(2)">投教运营服务</div>
        <div class="fd-tj-left-item1"></div>
      </div>
      <!-- 实体基地vr板块 -->
      <div class="fd-stjd-wrap-right" v-if="keyIndex === 0">
        <!-- <div class="fd-swiper-container" style="position:relative">
          <swiper class="swiper-no-swiping" :options="swiperOption" >
            <swiper-slide >
              <iframe class="fd-iframe" src="http://www.gpimg.cn/360/ziqhl983dy39e6kb/" frameborder="0"></iframe>
            </swiper-slide>
            <swiper-slide >
              <iframe class="fd-iframe" src="http://beyond.3dnest.cn/play/?m=zq_csfy_19" frameborder="0"></iframe>
            </swiper-slide>
            <swiper-slide >
              <iframe class="fd-iframe" src="http://beyond.3dnest.cn/play/?m=1b713560_TpPO_b6f9" frameborder="0"></iframe>
            </swiper-slide>
            <swiper-slide >
              <iframe class="fd-iframe" src="http://beyond.3dnest.cn/play/?m=c293fe96_IfxW_b6f9" frameborder="0"></iframe>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div> -->
        <div class="fd-swiper-container" style="position:relative;display:block;font-size: 0;">
          <div class="fd-swiper-left-wrap" style="width:810px; display: inline-block;vertical-align: top;">
            <iframe  class="fd-iframe" :src="keySrc" frameborder="0"></iframe>
          </div>
          <div class="fd-tabbar-wrap">
            <div @click="clickIframe('http://www.gpimg.cn/360/ziqhl983dy39e6kb/', 0)" :class="{'fd-check-iframe': keyIframeIndex === 0}">华西证券</div>
            <div @click="clickIframe('http://beyond.3dnest.cn/play/?m=zq_csfy_19', 1)" :class="{'fd-check-iframe': keyIframeIndex === 1}">招商证券</div>
            <div @click="clickIframe('http://beyond.3dnest.cn/play/?m=1b713560_TpPO_b6f9', 2)" :class="{'fd-check-iframe': keyIframeIndex === 2}">恒泰证券</div>
            <div @click="clickIframe('http://beyond.3dnest.cn/play/?m=c293fe96_IfxW_b6f9', 3)" :class="{'fd-check-iframe': keyIframeIndex === 3}">中泰证券</div>
          </div>
        </div>
      </div>
      <!-- 互联网投教板块 -->
      <div class="fd-hlwtj-wrap-right" v-if="keyIndex === 1">
        <div class="fd-item-hlwtj" style="margin-bottom:31px;position:relative" @click="jumptoOpen('https://edu.cnht.com.cn/htzq/portal/home/')">
          <div style="width:696px" class="fd-item-left">
            <img sa src="../assets/images/fd-img-001.png" alt="">
          </div>
          <div class="fd-item-right" style="width:295px;display:flex; justify-content:center; align-items:center">
            <img src="../assets/images/fd-left.png" style="margin-right:15px" alt="">
            <a style="color:#026BA7;font-size:18px;">恒泰证券投资者教育基地</a>
          </div>
        </div>
        <div class="fd-item-hlwtj" style="margin-bottom:31px" @click="jumptoOpen('http://edu.gtja.com/')">
          <div style="width:295px;display:flex; justify-content:center; align-items:center" class="fd-item-left">
            <a style="color:#026BA7;font-size:18px;">国泰君安证券投资者教育基地</a>
            <img style="margin-left:15px" src="../assets/images/fd-right.png" alt="">
          </div>
          <div class="fd-item-right">
            <img src="../assets/images/fd-img-002.png" alt="">
          </div>
        </div>
        <div class="fd-item-hlwtj" @click="jumptoOpen('http://61.160.69.110/investor/home/index.html')">
          <div class="fd-item-left">
            <img src="../assets/images/fd-img-003.png" alt="">
          </div>
          <div class="fd-item-right" style="width:295px;display:flex; justify-content:center; align-items:center">
            <img src="../assets/images/fd-left.png" alt="">
            <a style="color:#026BA7;font-size:18px; margin-left: 15px">国联证券投资者教育基地</a>
          </div>
        </div>
      </div>
      <!-- 投教运营服务板块 -->
      <div class="fd-tjyyfw-wrap-right" v-if="keyIndex === 2">
        <img style="margin-bottom: 41px;width:990px" src="../assets/images/fd-000-img.png" alt="">
        <div class="fd-tjyyfw-bottom-wrap">
          <div class="fd-tyyyfw-item">
            <h2>投教拓荒者</h2>
            <div class="fd-line-style"></div>
            <div class="fd-english-name">PIONEER</div>
            <div class="fd-item-text">国内第一家开始<br>专业承建并运营投教基地的公司</div>
          </div>
          <div class="fd-tyyyfw-item">
            <h2>成绩斐然</h2>
            <div class="fd-line-style" style="background-color:rgba(217,131,56,1)"></div>
            <div class="fd-english-name" style="color:rgba(217,131,56,1)">OUTSTANDING</div>
            <div class="fd-item-text">2017年，证监会公示的第二批国家级<br>证券期货投资者教育基地(共7家)中，<br>有3家由傲度信息承建;<br>迄今已于逾20家券商相继开展合作。</div>
          </div>
          <div class="fd-tyyyfw-item">
            <h2>全方位投教服务</h2>
            <div class="fd-line-style" style="background-color:rgba(38,184,184,1)"></div>
            <div class="fd-english-name" style="color:rgba(38,184,184,1)">FULL-SERVICE</div>
            <div class="fd-item-text">时刻把握政策走向,深耕学术研究；<br>线上线下相结合,展现趣味投教；<br>借助管理平台建设投教网络体系。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'tjt',
  data () {
    return {
      keySrc: 'http://www.gpimg.cn/360/ziqhl983dy39e6kb/',
      keyIframeIndex: 0,
      keyIndex: 0,
      swiperOption: {
        autoplay: false,
        Observer: {
          observerParents: true,
          observer: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // pagination : {
        //   el: '.swiper-pagination',
        //   clickable: true,
        //   clickableClass : 'my-pagination-clickable',
        // }, //轮播图中下标点显示
        // paginationClickable :true　　 //轮播图中下标点显示
      }
    }
  },
  methods:{
    adcpClick (index) {
      this.keyIndex = index
    },
    // 新开页面跳转
    jumptoOpen (src) {
      window.open(src)
    },
    clickIframe (src, index) {
      if (this.keyIframeIndex === index) {
        return
      } else {
        this.keyIframeIndex = index
        this.keySrc = src
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.fd-tabbar-wrap
  display inline-block
  vertical-align top
.fd-tabbar-wrap div
  color #026ba7
  cursor pointer
  background-color #f6f6f6
  margin-bottom 9px
  width 120px
  height 34px
  line-height 34px
  text-align center
  font-size 18px
  position relative
  &:after
    content ""
    position absolute
    top 0
    right -15px
    border-left 15px solid #f6f6f6
    border-top 17px solid transparent
    border-bottom 17px solid transparent
.swiper-button-prev
  width 30px !important
  height 30px !important
  background url('../assets/images/swiper_button_002.png') no-repeat !important
  background-size 100% 100%
.swiper-button-next
  width 30px !important
  height 30px !important
  background url('../assets/images/swiper_button_002.png')  100% center no-repeat !important
  background-size 100% 100%
.fd-item-hlwtj:hover
  cursor pointer
  box-shadow 0px 0px 13px 0px rgba(130,130,130,0.8) !important
  opacity 0.4
.fd-tjyyfw-wrap-right
  width 990px
  .fd-tjyyfw-bottom-wrap
    display flex
    justify-content space-around
    align-items baseline
    .fd-tyyyfw-item
      width 265px
      h2
        font-size 24px
        margin-bottom 8px
        color #222
        font-weight 500
      .fd-line-style
        width 62px
        height 4px
        margin-bottom 5px
        background rgba(2,107,167,1)
      .fd-english-name
        font-size 14px
        font-family 'ArialMT'
        font-weight 400
        color rgba(2,107,167,1)
        margin-bottom 20px
      .fd-item-text
        color #666
        line-height 25px
        font-size 16px
        text-align left
.fd-item-hlwtj
  display flex
  background-color rgba(255,255,255,1)
  box-shadow 0px 0px 13px 0px rgba(130,130,130,0.24)
.swiper-button-prev, .swiper-button-next
  z-index 999 !important
.fd-iframe
  width 810px
  height 500px
  box-shadow 0px 0px 13px 0px rgba(130,130,130,0.24)
.fd-checked
  background-color rgba(2,107,167,1) !important
  color white !important
.fd-swiper-container
  width 980px
  height 500px
.fd-tj-content-wrap
  width 1200px
  margin 0 auto 
  padding 80px 0
  display flex
  justify-content space-between
  .fd-tj-left-wrap
    width 175px
    .fd-tj-left-item
      width 175px
      height 50px
      color #026BA7
      font-size 20px
      line-height 50px
      text-align center
      cursor pointer
      background-color rgba(246,246,246,1)
      margin-bottom 10px
    .fd-tj-left-item1
      width 175px
      height calc(100% - 180px)
      background-color rgba(246,246,246,1)
.fd-tj-left-item:hover
  color #76c3ff !important
  text-decoration underline
.fd-check-iframe
  color white !important
  background-color: #026ba7 !important
  &:after
    border-left-color: #026ba7 !important
.fd-tabbar-wrap div:hover
  color #76c3ff !important
  text-decoration underline
</style>


